import { Card } from "antd-mobile";
import React from "react";
import { useNavigate } from "react-router-dom";
import MainButton from "../components/Button";
import I404 from "../components/icons/404";

// import { Container } from './styles';

function Error404() {
  let navigate = useNavigate();
  return (
    <Card style={{ marginTop: "20px",marginBottom: "20px", color: "#fff", textAlign: "center" }}>
      <div style={{ marginBottom: "50px" }}>
        <h1 style={{fontSize: '50px'}}>Error</h1>
        <h2 style={{fontSize: '35px'}}>404</h2>
        <h3>Página não encontrada! :( </h3>
      </div>
      <I404 />
      <div style={{marginTop: '100px'}}>
        <MainButton
          text="Voltar para pagina inicial"
          className="button more"
          style={{ width: "300px", height: "50px" }}
          onClick={() => {navigate('/')}}
        />
      </div>
    </Card>
  );
}

export default Error404;
