/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Col, Layout, Row } from "antd";
import { Footer } from "antd-mobile";

function FooterPage() {
  // const { Footer: AntFooter } = Layout;

  return (
    <Footer
          label={
            <div>
              蚂蚁财富
            </div>
          }
        ></Footer>
    // <AntFooter style={{ color: "#fafafa"}}>
    //   <Row className="just">
    //     <Col xs={24} md={12} lg={12}>
    //       <div className="copyright">© 2022</div>
    //     </Col>
    //   </Row>
    // </AntFooter>
  );
}

export default FooterPage;
