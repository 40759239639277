import { Button } from 'antd'
import React from 'react'

// import { Container } from './styles';

function MainButton({ text, func, ...rest }) {
  return (
    <Button block size="large" {...rest}>
      {text}
    </Button>
  )
}

export default MainButton
