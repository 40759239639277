import { Navigate, useRoutes } from "react-router-dom";
import Main from "../components/layout/Main";
import MainProfile from "../components/layout/MainProfile";
import Detail from "../pages/Detail";
import Error404 from "../pages/Error404";
import Forgot from "../pages/Forgot";
import Success from "../pages/Forgot/success";
import Home from "../pages/Home";
import Manager from "../pages/Manager";
import Movements from "../pages/Movements";
import Profile from "../pages/Profile";
import ProfileEdit from "../pages/Profile/edit";
import Reset from "../pages/Reset";
import SignIn from "../pages/SignIn";
import WithdrawalConfirmation from "../pages/WithdrawalConfirmation";
import { isAuthenticated } from "../services/auth";

export default function MainRouter() {

  return useRoutes([
    {
      path: "/",
      element: isAuthenticated() ? <Main /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/" /> },
        { path: "", element: <Home /> },
        { path: "/movimentacao", element: <Movements /> },
        { path: "/detalhes", element: <Detail /> },
        { path: "/gerente/:id", element: <Manager /> },
      ],
    },
    {
      element: isAuthenticated() ? <MainProfile /> : <Navigate to="/login" />,
      children: [
        { path: "/perfil", element: <Profile /> },
        { path: "/perfil/editar/:id", element: <ProfileEdit /> },
      ],
    },
    { path: "/reset/:token", element: <Reset /> },
    { path: "/forgot", element: <Forgot /> },
    { path: "/forgot-success", element: <Success /> },
    { path: "/confirmacao/:id", element: <WithdrawalConfirmation /> },
    { path: "/login", element: <SignIn /> },
    { path: "*", element: <Error404 /> },
  ]);
}
