import { useQuery } from '@tanstack/react-query'
import api from '../../services/api'

const getManager = async (managerId) => {
  try {
    const data = await api.get(
      `${process.env.REACT_APP_API}/v1/profile/manager-account?id=${managerId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return data
  } catch (error) {
    console.log(error.message)
  }
}

export function useManagerFetchRequest(id) {
  return useQuery(['manager', id], () => getManager(id), {
    onSuccess: (data) => {
      return data.data
    },
  })
}
