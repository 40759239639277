import { useQuery } from "@tanstack/react-query";
import { AutoCenter } from "antd-mobile";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../Helpers/moneyFormat";
import MainButton from "../components/Button";
import Withdrawal from "../components/icons/Withdrawal";
import api from "../services/api";

// import { Container } from './styles';

function WithdrawalConfirmation() {
  let { id } = useParams();
  let navigate = useNavigate();
  const { isLoading, data } = useQuery(
    ["success", 1],
    () =>
      api
        .get(
          `${process.env.REACT_APP_API}/v1/withdrawal/request-withdrawal-completed?id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {}),
    {
      staleTime: 2000,
    }
  );

  const hendleReturnHomePage = () => {
    navigate("/");
  };

  return (
    <>
      <div className="title-completed">
        <h1>Solicitação de saque</h1>
      </div>

      <AutoCenter>
        <Withdrawal />
      </AutoCenter>

      <div className="title-completed">
        <h2>Solicitação bem-sucedida</h2>
      </div>

      <div className="amount-completed">
        <p>{formatCurrency(data?.amount, "BRL")}</p>
      </div>

      <div className="data-completed">
        {/* <p>
          A transferência, domingo, 15 de Janeiro de 2023, para Hugo Tostes, foi
          bem-sucedida
        </p> */}
      </div>

      <div className="return-completed">
        <MainButton
          onClick={hendleReturnHomePage}
          text="Retornar a tela principal"
          className="button more"
          style={{
            width: "100%",
            height: "60px",
            boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        />
      </div>

      {/* <div className="new-aport">
        <p>Deseja fazer outro aporte?</p>
      </div> */}
    </>
  );
}

export default WithdrawalConfirmation;
