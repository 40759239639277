import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Card, DotLoading, Form, Input, Space, Toast } from "antd-mobile";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useProfileUpdateRequest } from "../../RQhooks/profile/useProfile.query";
import { useUserLoggedFetchRequest } from "../../RQhooks/user/useUser.query";
import MainButton from "../../components/Button";
import Image from "../../components/Image";

// import { Container } from './styles';

function ProfileEdit() {
  const [form] = Form.useForm();
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const validateForm = yup.object().shape({
    required: "'${name}' is required!",
  });

  const [fileList, setFileList] = useState([
    {
      url: "demoSrc",
    },
  ]);

  const [inputWhatsapp, setInputWhatsapp] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPix, setInputPix] = useState("");

  const { data: user, isLoading: loadingUser } = useUserLoggedFetchRequest();
  const dataUser = user?.data?.data;

  const { mutateAsync, isLoading: saving } = useMutation(
    useProfileUpdateRequest,
    {
      onSettled: () => {
        console.log("onSettled");
      },
      onSuccess: () => {
        queryClient.invalidateQueries("profile");
        Toast.show({
          content: "Dados salvos com sucesso!",
          icon: "success",
        });
        navigate("/perfil");
      },
    }
  );
  const onFinish = (values) => {
    mutateAsync({ values, id });
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  if (loadingUser) {
    return "carregando...";
  }

  const imageProfile = `${dataUser?.imageThumbnailUrl}?tr=w-300,h-300`;
  return (
    <>
      <Card>
        <Space
          align="center"
          block
          direction="horizontal"
          style={{
            padding: "30px 0px 70px 30px",
            margin: "0px -15px -60px -10px",
            borderRadius: "50px 50px 0 0",
            background: "#2B2B2B",
          }}
        >
          <Image
            path={`profile-picture/${dataUser?.imageName}`}
            width={40}
            height={40}
          />
          <Space direction="vertical" style={{ color: "#fff" }}>
            <span>
              <h3 className="">{dataUser?.name}</h3>
              <span>Token {dataUser?.account_number}</span> <br />
              <span>
                <small>RTX Operações</small>
              </span>
            </span>
          </Space>
        </Space>
      </Card>

      <Card style={{ padding: 0 }}>
        <Space
          align="center"
          block
          direction="vertical"
          className="profile-list-info"
          style={{
            padding: "20px 0 0 0",
            borderRadius: "50px 50px 0 0",
            background: "#050505",
            width: "100%",
            color: "#fff",
          }}
        >
          <h1 style={{ textAlign: "center", color: "#fff" }}>
            Editar meus dados{" "}
          </h1>

          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="row-col"
            layout="vertical"
            initialValues={{
              whatsapp: dataUser?.whatsapp,
              phone: dataUser?.phone,
              email: dataUser?.email,
              pix: dataUser?.pix,
            }}
          >
            <Form.Item
              className="input-bg"
              name="whatsapp"
              label="Whatsapp"
              // rules={[
              //   { required: true, message: "Whatsapp não pode ser vazio" },
              // ]}
            >
              <Input placeholder="Whatsapp" />
            </Form.Item>
            <Form.Item className="input-bg" name="phone" label="Telefone">
              <Input placeholder="Telefone" />
            </Form.Item>
            <Form.Item className="input-bg" name="pix" label="Pix">
              <Input placeholder="Pix" />
            </Form.Item>

            <Form.Item>
              <MainButton
                text={
                  saving ? (
                    <span style={{ fontSize: 24 }}>
                      <DotLoading color="primary" />
                    </span>
                  ) : (
                    "Salvar"
                  )
                }
                className="button more"
                htmlType="submit"
                style={{ width: "100%", height: "50px" }}
              />
            </Form.Item>
          </Form>
        </Space>
      </Card>

      {/* <ImageUploader
      style={{ '--cell-size': '90px' }}
      value={fileList}
      onChange={setFileList}
      // upload={mockUpload}
    /> */}
    </>
  );
}

export default ProfileEdit;
