import { QueryClientProvider } from "@tanstack/react-query";
import { SafeArea } from "antd-mobile";
import { useEffect, useState } from "react";
import "./App.css";
import { queryClient } from "./clientProvider";
import MainRouter from "./routes";

function App() {
  const [showButton, setShowButton] = useState(false);
  let displayMode = "browser tab";
  console.log("DISPLAY_MODE_LAUNCH:", displayMode);

  useEffect(() => {
    const isPWA =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone === true;
    if (!isPWA) {
      const isA2HS = "beforeinstallprompt" in window;
      if (isA2HS) {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          setShowButton(true);
        });
      }
    }
  }, []);

  const addToHomeScreen = () => {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) return;

    promptEvent.prompt();
    promptEvent.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt.");
      } else {
        console.log("User dismissed the A2HS prompt.");
      }
      setShowButton(false);
    });
  };

  const handleClickSkipWaiting = () => {
    window.location.reload(true);
    // if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    // navigator.serviceWorker.controller.postMessage({ type: "SKIP_WAITING" });
    // }
  };

  return (
    <QueryClientProvider client={queryClient}>
      {/* {showButton && (
        <MainButton
          onClick={addToHomeScreen}
          text="Adicionar a tela inicial"
          className="button more"
          style={{
            width: "100%",
            height: "60px",
            background: "rgba(0, 255, 71, 0.62)",
            boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        />
      )} */}
      <SafeArea position="top" />
      <div id="notification">
        <p>
          Uma nova versão está disponível. Clique{" "}
          <span
            style={{
              color: "#000",
              textDecoration: "underline",
              fontSize: "large",
            }}
            onClick={handleClickSkipWaiting}
          >
            aqui
          </span>{" "}
          para atualizar.
        </p>
      </div>
      <MainRouter />
      <SafeArea position="bottom" />
    </QueryClientProvider>
  );
}

export default App;
