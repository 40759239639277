import { Avatar, Card, List, Space } from "antd-mobile";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { formatNumberWhatsapp } from "../Helpers/formatNumberWhatsapp";
import { useManagerFetchRequest } from "../RQhooks/profile/useProfile.query";

// import { Container } from './styles';

function Manager() {
  let { id } = useParams();
  const { data: manager, isLoading } = useManagerFetchRequest(id);

  const handleOpenWhatsapp = (e) => {
    // format the message with the phone number and message
    const url = `whatsapp://send?phone=+55${formatNumberWhatsapp(e)}`;
    // open the WhatsApp Web or mobile app
    window.open(url, "_blank");
  };

  const dataManager = manager?.data;
  console.log(dataManager);

  return (
    <Card>
      <List>
        <List.Item
          style={{ color: "#fff" }}
          prefix={
            <Avatar
              src={
                dataManager?.imageUrl ||
                "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Man_Silhouette2.jpg/800px-Man_Silhouette2.jpg"
              }
            />
          }
          description=""
        >
          {dataManager?.name}
        </List.Item>
      </List>

      <Space
        align="center"
        block
        direction="vertical"
        className="profile-list-info"
        style={{
          width: "100%",
          color: "#fff",
          margin: "30px 0 20px 0",
        }}
      >
        <List className="profile-list-info">
          <h4 style={{ textAlign: "center" }}>Contato</h4>
          <br />
          <List.Item>Fone: {dataManager?.phone}</List.Item>
          <List.Item>
            WhatsApp:{" "}
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              onClick={() => handleOpenWhatsapp(dataManager?.whatsapp)}
            >
              {" "}
              {dataManager?.whatsapp}{" "}
            </Link>{" "}
          </List.Item>
          <List.Item>Email: {dataManager?.email}</List.Item>
        </List>
      </Space>
    </Card>
  );
}

export default Manager;
