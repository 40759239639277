import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "../components/Button";
import MainLogin from "../components/layout/MainLogin";
import Toast from "antd-mobile/es/components/toast";
import { DotLoading, Form, Input, Space } from "antd-mobile";
import { useState } from "react";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import { formatCpfCnpjInput } from "../Helpers/formatCpfCnpjInput";
// import { Container } from './styles';

function SignIn() {
  const formRef = React.useRef(null);
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const loginUser = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/site/login`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutate } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data.status === 400 || data.status === 401) {
        Toast.show({
          content: data.message,
          icon: "fail",
        });
        return;
      }

      Toast.show({
        content: "Login realizado com sucesso!",
        icon: "success",
      });
      localStorage.setItem(
        process.env.REACT_APP_ACCESS_TOKEN,
        data.data.token.token
      );
      setTimeout(1000);
      navigate("/");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onFinish = (values) => {
    mutate(values);
  };

  const onFinishFailed = (errorInfo) => {};

  const handleCpfCnpjChange = (valueDocument) => {
    formRef.current?.setFieldsValue({
      username: formatCpfCnpjInput(valueDocument),
    });
  };

  return (
    <MainLogin>
      <Form
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="row-col"
        layout="vertical"
      >
        <Form.Item
          className="username"
          name="username"
          rules={[
            {
              required: true,
              message: "Por favor verifique os dados de acesso!",
            },
          ]}
        >
          <Input
            placeholder="CPF/CNPJ"
            type="text"
            onChange={(value) => handleCpfCnpjChange(value)}
          />
        </Form.Item>

        <Form.Item
          style={{ position: "relative" }}
          name="password"
          className="input-password"
          extra={
            <>
              <div
                className="eye"
                style={{
                  background: "rgb(34,34,34)",
                  padding: "13px 10px",
                  top: "12px",
                  position: "absolute",
                  margin: "0 0 0 -35px",
                  borderRadius: '0 10px 10px 0'
                }}
              >
                {!visible ? (
                  <EyeInvisibleOutline onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setVisible(false)} />
                )}
              </div>
            </>
          }
          rules={[
            {
              required: true,
              message: "Por favor verifique sua senha!",
            },
          ]}
        >
          <Input
            placeholder="Senha"
            type={visible ? "text" : "password"}
            style={{ background: "" }}
          />
        </Form.Item>

        <Form.Item>
          <MainButton
            text={
              isLoading ? (
                <span style={{ fontSize: 24 }}>
                  <DotLoading color="primary" />
                </span>
              ) : (
                "Entrar"
              )
            }
            className="button more"
            htmlType="submit"
          />
        </Form.Item>
      </Form>

      <br />
      <br />

      <Space
        align="center"
        block
        direction="vertical"
        style={{
          textAlign: "center",
        }}
      >
        <Link to="/forgot" className="reset-pass">
          Alterar senha
        </Link>
      </Space>
    </MainLogin>
  );
}

export default SignIn;
