import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dollar } from "../icons/Dollar";
import { Home } from "../icons/Home";
import { Movement } from "../icons/Movement";

// import { Container } from './styles';

const tabs = [
  {
    key: "/",
    title: "",
    icon: <Home color="none" stroke="#aaa" />,
  },
  {
    key: "movimentacao",
    title: "",
    icon: <Movement color="none" stroke="#aaa" />,
  },
  {
    key: "detalhes",
    title: "",
    icon: <Dollar color="none" stroke="#aaa" />,
  },
];

function BottomBar() {
  let navigate = useNavigate();

  const setRouteActive = (value) => {
    navigate(value);
  };

  return (
    <div className="bottom backdrop" style={{ padding: "0px 0 10px 0" }}>
      <ul style={{ color: "#aaa" }}>
        {tabs.map((item, index) => (
          <li key={index}>
            <Link to={item.key}>{item.icon}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BottomBar;
