import { useQuery } from "@tanstack/react-query";
import { Affix, Drawer, Layout } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../logo.png";
import Button from "../Button";

const { Header: AntHeader, Content, Sider } = Layout;

function MainLogin({ children }) {
  const handleLogin = () => {};
  return (
    <Layout className={`layout-login`}>
      <div className="logo">
        <img src={logo} alt="" style={{ height: "90px" }} />
      </div>
      <div className="velas"></div>
      <div className="middle"></div>
      <p className="text">Be part of it.</p>
      <div className="form-login">{children}</div>
    </Layout>
  );
}

export default MainLogin;
