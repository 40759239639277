import { useMutation } from "@tanstack/react-query";
import { Layout } from "antd";
import { Card, DotLoading, Form, Input, Space, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainButton from "../../components/Button";
import MainLogin from "../../components/layout/MainLogin";
import api from "../../services/api";
import logo from "../../logo.png";
import { formatCpfCnpjInput } from "../../Helpers/formatCpfCnpjInput";
// import { Container } from './styles';
import ReCAPTCHA from "react-google-recaptcha";

function Forgot() {
  const [localRecaptcha, setLocalRecaptcha] = useState(null);
  const formRef = React.useRef(null);
  let { token } = useParams();
  let navigate = useNavigate();
  console.log(token);
  const loginUser = async (user) => {
    const response = await api.post(
      `${process.env.REACT_APP_API}/site/forgot`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutate } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data.status === 400 || data.status === 401) {
        Toast.show({
          content: data.message,
          icon: "fail",
        });
        return;
      }

      Toast.show({
        content: "Solicitação de troca realizada com sucesso!",
        icon: "success",
      });

      setTimeout(1000);
      navigate("/forgot-success");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onFinish = (values) => {
    console.log(captchaRef.current.getValue())
    if (!captchaRef.current.getValue()) {
      Toast.show({
        content: 'Preencha o re-captcha!',
        icon: "fail",
      });
      return;
    }
    console.log('certo')
    mutate(values);
  };

  const onFinishFailed = (errorInfo) => {};

  const handleCpfCnpjChange = (valueDocument) => {
    formRef.current?.setFieldsValue({
      username: formatCpfCnpjInput(valueDocument),
    });
  };

  const captchaRef = useRef(null);
  const grecaptchaObject = window.grecaptcha;
  
  return (
    <Layout className={`layout-login`}>
      <div className="logo">
        <img src={logo} alt="" style={{ height: "80px" }} />
      </div>

      <Form
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="row-col"
        layout="vertical"
      >
        <Form.Item
          className="username"
          name="username"
          rules={[
            {
              required: true,
              message: "Nao pode estar em branco",
            },
          ]}
        >
          <Input
            placeholder="CPF/CNPJ"
            onChange={(value) => handleCpfCnpjChange(value)}
          />
        </Form.Item>

        <Form.Item className="username">
          <ReCAPTCHA
            sitekey="6LcYw8wkAAAAAHQz6PjhHcTNiAEIAmGZbvVG_PK7"
            ref={captchaRef}
            grecaptcha={grecaptchaObject}
          />
        </Form.Item>

        <Form.Item>
          <MainButton
            text={
              isLoading ? (
                <span style={{ fontSize: 24 }}>
                  <DotLoading color="primary" />
                </span>
              ) : (
                "Resetar"
              )
            }
            className="button more"
            htmlType="submit"
          />
        </Form.Item>
      </Form>
    </Layout>
  );
}

export default Forgot;
