import { useMutation } from "@tanstack/react-query";
import { Layout } from "antd";
import { Card, DotLoading, Form, Input, Space, Toast } from "antd-mobile";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainButton from "../../components/Button";
import MainLogin from "../../components/layout/MainLogin";
import api from "../../services/api";

// import { Container } from './styles';

function Reset() {
  let { token } = useParams();
  let navigate = useNavigate();
  console.log(token);
  const loginUser = async (user) => {
    const response = await api.post(
      `${process.env.REACT_APP_API}/site/reset?token=${token}`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutate } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data.status === 400 || data.status === 401) {
        Toast.show({
          content: data.message,
          icon: "fail",
        });
        return;
      }

      Toast.show({
        content: "",
        icon: "success",
      });
      // localStorage.setItem(
      //   process.env.REACT_APP_ACCESS_TOKEN,
      //   data.data.token.token
      // );
      setTimeout(1000);
      navigate("/");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onFinish = (values) => {
    // console.log(values)
    mutate(values);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <Layout className={`layout-login`}>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="row-col"
        layout="vertical"
      >
        <Form.Item
          className="password"
          name="password"
          rules={[
            {
              required: true,
              message: "Nao pode estar em branco",
            },
          ]}
        >
          <Input placeholder="Nova Senha" />
        </Form.Item>

        <Form.Item
          className="password"
          name="password"
          rules={[
            {
              required: true,
              message: "Nao pode estar em branco",
            },
          ]}
        >
          <Input placeholder="Repita a Senha" />
        </Form.Item>

        <Form.Item>
          <MainButton
            text={
              isLoading ? (
                <span style={{ fontSize: 24 }}>
                  <DotLoading color="primary" />
                </span>
              ) : (
                "Entrar"
              )
            }
            className="button more"
            htmlType="submit"
          />
        </Form.Item>
      </Form>
    </Layout>
  );
}

export default Reset;
