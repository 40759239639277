import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Modal, Space, Toast } from "antd-mobile";
import React from "react";
import CurrencyInput from "react-currency-input";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../Helpers/moneyFormat";
import { useWithdrawalPostRequest } from "../../RQhooks/withdrawal/useWithdrawal.query";
import MainButton from "../Button";

// import { Container } from './styles';

function WithdrawalButton({ wallet }) {
  const formRef = React.useRef(null);
  const queryClient = useQueryClient();

  let navigate = useNavigate();

  const { mutateAsync, isLoading: saving } = useMutation(
    useWithdrawalPostRequest,
    {
      onSettled: () => {
        console.log("onSettled");
      },
      onSuccess: (data) => {
        // queryClient.invalidateQueries("profile");
        if (data.data.status === 403) {
          Toast.show({
            content: data.data.message,
            icon: "error",
          });
          return;
        }
        Toast.show({
          content: "Solicitação feita com sucesso!",
          icon: "success",
        });
        Modal.clear()
        navigate(`/confirmacao/${data.data.data.id}`);
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );
  const onFinish = (values) => {
    var amount = values.amount;
    amount = amount.replace("US$ ", "");
    mutateAsync({ amount });
  };

  const onFinishFailed = (errorInfo) => { };

  return (
    <MainButton
      text="Sacar"
      className={`button withdrawal`}
      style={{ width: "100%" }}
      onClick={() =>
        Modal.show({
          content: (
            <>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div className="modal-title">
                  <p className="description">Disponível para Saque</p>
                  <p className="value">
                    {formatCurrency(wallet.available_for_withdrawal)}
                  </p>
                </div>
                <div>
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="row-col"
                    layout="vertical"
                  >
                    <Form.Item
                      className="withdrawal-input"
                      clearable
                      name="amount"
                    >
                      <CurrencyInput
                        autoFocus={true}
                        className="form-input px-4 py-3 rounded-full"
                        prefix=" US$ "
                        id="input-example"
                        decimalsLimit="2"
                        decimalSeparator=","
                        thousandSeparator="."
                        onValueChange={(value, name) =>
                          console.log(value, name)
                        }
                        style={{
                          margin: "-5px 0px",
                          height: "50px",
                          border: "0px transparent",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "0 0 0 0px",
                        }}
                        max={wallet.available_for_withdrawal}
                        min="0"
                      />
                    </Form.Item>
                    <Form.Item>
                      <MainButton
                        text="Solicitar"
                        htmlType="submit"
                        className="button more"
                        style={{
                          width: "100%",
                          height: "60px",
                          background: "rgba(0, 255, 71, 0.62)",
                          boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </Space>
            </>
          ),
          closeOnAction: true,
          // onConfirm: () => {
          //   console.log("Confirmed");
          // },
          destroyOnClose: true,
          closeOnMaskClick: false,
          showCloseButton: true,
          maskStyle: { backdropFilter: "blur(50px)" },
        })
      }
    />
  );
}

export default WithdrawalButton;
