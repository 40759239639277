import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
      refetchOnWindowFocus: false,
    },
    retry: 10,
  },
});

axios.get("/");

export default queryClient;
