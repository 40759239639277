import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";

const getTransactions = async () => {
  try {
    const data = await api.get(`${process.env.REACT_APP_API}/v1/transaction`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useTransactionsFetchRequest() {
  return useQuery(["transactions"], () => getTransactions(), {
    onSuccess: (data) => {
      return data.data;
    },
  });
}
