import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";

const getProfile = async () => {
  try {
    const data = await api.get(
      `${process.env.REACT_APP_API}/v1/user/user-online`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getManager = async (id) => {
  try {
    const data = await api.get(
      `${process.env.REACT_APP_API}/v1/profile/manager-account?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const putProfile = async ({ id, values }) => {
  try {
    const data = await api.put(
      `${process.env.REACT_APP_API}/v1/profile/update?id=${id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const uploadPicture = async (dataImage) => {
  try {
    const data = await api.patch(
      `${process.env.REACT_APP_API}/v1/profile/picture-upload`,
      dataImage,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useProfileFetchRequest() {
  return useQuery(["profile"], () => getProfile(), {
    onSuccess: (data) => {
      return data.data;
    },
  });
}

export function useManagerFetchRequest(id) {
  return useQuery(["manager"], () => getManager(id), {
    onSuccess: (data) => {
      return data.data;
    },
  });
}

export function useProfileUpdateRequest(data) {
  return putProfile(data);
}

export function useUploadProfilePicture(data) {
  return uploadPicture(data);
}
