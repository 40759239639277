import { Grid } from "antd-mobile";
import React from "react";
import { formatNumberWhatsapp } from "../Helpers/formatNumberWhatsapp";
import { formatCurrency } from "../Helpers/moneyFormat";
import { showButtonBetweenDates } from "../Helpers/showButtonBetweenDates";
import { useManagerFetchRequest } from "../RQhooks/manager/useManager.query";
import { useUserLoggedFetchRequest } from "../RQhooks/user/useUser.query";
import { useWalletFetchRequest } from "../RQhooks/wallet/useWallet.query";
import MainButton from "../components/Button";
import CardDefault from "../components/Card";
import CardTotalWithdrawal from "../components/CardTotalWithdrawal";
import WithdrawalButton from "../components/Withdrawal";

// import { Container } from './styles';

function Detail() {
  const { data: user, isLoading: loadingUser } = useUserLoggedFetchRequest();
  const userData = user?.data?.data;
  const { data: wallet, isLoading: loadingWallet } = useWalletFetchRequest();
  const dataWallet = wallet?.data?.data;
  const { data: manager } = useManagerFetchRequest(userData?.manager);
  const managerData = manager?.data;

  const handleRequestNewContribution = () => {
    const message = "Olá, gostaria de fazer um novo depósito em minha conta.";
    const whatsapp = formatNumberWhatsapp(managerData?.whatsapp);
    const url = `whatsapp://send?text=${message}&phone=+55${whatsapp}`;
    window.open(url, "_blank");
  };

  let lengthItems = 2;
  if (showButtonBetweenDates()) {
    lengthItems = "";
  }

  return (
    <>
      <div style={{ color: "#fff", padding: "0 0 10px 20px" }}>
        <h4>Saldo em operação</h4>
        <h1>
          {loadingWallet
            ? "carregando"
            : formatCurrency(dataWallet?.amount || 0)}
        </h1>
      </div>
      {showButtonBetweenDates() ? (
        <CardTotalWithdrawal wallet={dataWallet} />
      ) : (
        <CardDefault>
          <h3>Participação disponível do dia 01 ao dia 05</h3>
        </CardDefault>
      )}

      <div style={{ margin: "10px 10px", height: "70px" }}>
        <Grid columns={2} gap={12}>
          <Grid.Item span={lengthItems}>
            {managerData?.whatsapp != null ? (
              <MainButton
                text="Aportar"
                className="button aport"
                style={{ width: "100%" }}
                onClick={handleRequestNewContribution}
              />
            ) : (
              <MainButton
                text="Sem whatsapp cadastrado"
                color="warning"
                className="button"
                style={{ width: "100%" }}
                disabled
              />
            )}
          </Grid.Item>
          {showButtonBetweenDates() ? (
            <Grid.Item>
              <WithdrawalButton wallet={dataWallet} />
            </Grid.Item>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </>
  );
}

export default Detail;
