import { DropboxOutlined, UndoOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Menu } from "antd";
import axios from "axios";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Dashboard } from "../icons/Dashboard";
import { Profile } from "../icons/Profile";
import { Signup } from "../icons/Signup";

function Sidenav(props) {
  let navigate = useNavigate();
  const { color } = props;
  let { pathname } = useLocation();
  let page = pathname.split("/");

  pathname = pathname.replace("/", "");


  const {data: accessGiven} = useQuery(["access", 1], () => axios.get(`${process.env.REACT_APP_API}/site/get-access`,{
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)}`, 
    }
})
  .then((res) => {
    if (!res.data) {
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      navigate('/login')
    }
    return res?.data
  })
  .catch((err) => console.log('error',err))
  );

  const MenuItems = [
    {
      title: "Dashboard",
      link: "painel",
      icon: <Dashboard color={color} />,
      display: accessGiven >= 20,
    },

    {
      title: "Investidores",
      link: "investidor",
      icon: <Profile />,
      display: accessGiven >= 20,
    },
    {
      title: "Lançamento Retrô",
      link: "lancamento",
      icon: <UndoOutlined color={color} />,
      display: accessGiven >= 99,
    },
    {
      title: "Lançamento em Lote",
      link: "lote",
      icon: <DropboxOutlined color={color} />,
      display: accessGiven >= 99,
    },
  ];

  let activePage = null;
  if (page.length === 1) {
    activePage = page[0];
  }
  if (page.length > 1) {
    activePage = page[1];
  }

  return (
    <>
      <div className="brand">
        {/* <img src={logo} alt="" style={{ height: "60px" }} /> */}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {MenuItems.map((menu, index) => {
          return (
            menu.display && (
              <Menu.Item key={index}>
                <NavLink to={`${menu.link}`}>
                  <span
                    className="icon"
                    style={{
                      background: activePage === `${menu.link}` ? color : "",
                    }}
                  >
                    {menu.icon}
                  </span>
                  <span className="label">{menu.title}</span>
                </NavLink>
              </Menu.Item>
            )
          );
        })}
        <hr />
        <Menu.Item key="7">
          <NavLink to="/sign-in">
            {/* <span className="icon">
              <Signin />
            </span> */}
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">
              <Signup />
            </span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
