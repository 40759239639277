import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import BottomBar from "./BotomBar";
import Header from "./Header";

const { Header: AntHeader, Content } = Layout;

function MainProfile() {
  return (
    <>
      <Layout className={``}>
        <AntHeader>
          <Header />
        </AntHeader>
        <Content className="">
          <Outlet />
        </Content>
      </Layout>
      <BottomBar className="backdrop" />
    </>
  );
}

export default MainProfile;
