import React from "react";
import { useTransactionsFetchRequest } from "../RQhooks/transactions/useTransactions.query";
import ListMovement from "../components/ListMovement";

function Movements() {
  const { data: transactions } = useTransactionsFetchRequest();

  return (
    <>
      <ListMovement transactions={transactions?.data} moreItems={false} />
    </>
  );
}

export default Movements;
