import React from "react";

// import { Container } from './styles';

function Withdrawal() {
  return (
    <svg
      width="374"
      height="379"
      viewBox="0 0 374 379"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.4683 151.167C68.5424 148.142 88.3604 146.812 95.0391 165.686C101.718 184.56 113.067 186.617 117.906 185.286"
        stroke="#00FFEA"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M263.094 187.388C272.168 184.363 291.986 183.032 298.665 201.907C305.344 220.781 316.693 222.838 321.532 221.507"
        stroke="#00FFEA"
        stroke-width="3"
        stroke-linecap="round"
      />
      <g filter="url(#filter0_d_1_6)">
        <path
          d="M181.041 123.308C184.243 119.346 190.282 119.346 193.484 123.308L200.214 131.633C202.203 134.094 205.456 135.151 208.513 134.329L218.85 131.55C223.769 130.227 228.655 133.777 228.917 138.863L229.468 149.554C229.631 152.715 231.642 155.482 234.597 156.613L244.594 160.441C249.351 162.262 251.217 168.006 248.439 172.275L242.601 181.248C240.875 183.901 240.875 187.321 242.601 189.974L248.439 198.947C251.217 203.216 249.351 208.96 244.594 210.781L234.597 214.609C231.642 215.74 229.631 218.508 229.468 221.668L228.917 232.359C228.655 237.445 223.769 240.995 218.85 239.673L208.513 236.893C205.456 236.071 202.203 237.128 200.214 239.589L193.484 247.915C190.282 251.876 184.243 251.876 181.041 247.915L174.311 239.589C172.322 237.128 169.069 236.071 166.013 236.893L155.675 239.673C150.756 240.995 145.87 237.445 145.608 232.359L145.057 221.668C144.894 218.508 142.884 215.74 139.928 214.609L129.931 210.781C125.174 208.96 123.308 203.216 126.086 198.947L131.924 189.974C133.65 187.321 133.65 183.901 131.924 181.248L126.086 172.275C123.308 168.006 125.174 162.262 129.931 160.441L139.928 156.613C142.884 155.482 144.894 152.715 145.057 149.554L145.608 138.863C145.87 133.777 150.756 130.227 155.675 131.55L166.013 134.329C169.069 135.151 172.322 134.094 174.311 131.633L181.041 123.308Z"
          fill="url(#paint0_linear_1_6)"
        />
      </g>
      <path
        d="M204.717 173.611L180.717 197.611L169.808 186.702"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M266.967 116.572C267.281 114.662 270.027 114.662 270.342 116.572L271.315 122.479L277.222 123.452C279.132 123.767 279.132 126.513 277.222 126.827L271.315 127.8L270.342 133.708C270.027 135.617 267.281 135.617 266.967 133.708L265.994 127.8L260.087 126.827C258.177 126.513 258.177 123.767 260.087 123.452L265.994 122.479L266.967 116.572Z"
        fill="white"
      />
      <path
        d="M106.803 228.232C107.102 226.418 109.71 226.418 110.009 228.232L110.933 233.844L116.545 234.768C118.36 235.067 118.36 237.676 116.545 237.974L110.933 238.899L110.009 244.511C109.71 246.325 107.102 246.325 106.803 244.511L105.879 238.899L100.267 237.974C98.4524 237.676 98.4525 235.067 100.267 234.768L105.879 233.844L106.803 228.232Z"
        fill="white"
      />
      <path
        d="M101.69 121.339C101.91 120.003 103.832 120.003 104.053 121.339L104.734 125.474L108.869 126.155C110.206 126.376 110.206 128.298 108.869 128.518L104.734 129.199L104.053 133.334C103.832 134.671 101.91 134.671 101.69 133.334L101.009 129.199L96.8739 128.518C95.5371 128.298 95.5371 126.376 96.8739 126.155L101.009 125.474L101.69 121.339Z"
        fill="white"
      />
      <path
        d="M276.581 247.874C276.802 246.537 278.724 246.537 278.944 247.874L279.625 252.009L283.76 252.69C285.097 252.91 285.097 254.832 283.76 255.053L279.625 255.734L278.944 259.869C278.724 261.206 276.802 261.206 276.581 259.869L275.9 255.734L271.765 255.053C270.428 254.832 270.428 252.91 271.765 252.69L275.9 252.009L276.581 247.874Z"
        fill="white"
      />
      <circle cx="271.089" cy="164.787" r="4" fill="#FFE609" />
      <circle cx="169.808" cy="84.7374" r="4" fill="#FFE609" />
      <circle cx="225.579" cy="292.211" r="4" fill="#FFE609" />
      <circle cx="148.404" cy="267.84" r="2.5" fill="#FFE609" />
      <defs>
        <filter
          id="filter0_d_1_6"
          x="0.78833"
          y="0.336731"
          width="372.949"
          height="378.549"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="62" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.219608 0 0 0 0 0.937255 0 0 0 0 0.490196 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_6"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_6"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_6"
          x1="124.788"
          y1="185.611"
          x2="249.737"
          y2="185.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#11998E" />
          <stop offset="1" stop-color="#38EF7D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Withdrawal;
