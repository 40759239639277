const types = {
  1: 'Credito de rentabilidade - ',
  2: 'Aporte',
  8: 'Reinvestimento automatico',
  5: 'Saque',
}

export const type_format = (type) => {
  return types[type] || ''
}
