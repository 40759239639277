import { useEffect, useState } from 'react';

import { BellOutlined } from '@ant-design/icons';

import { Badge, Card, NavBar, Popup, Space } from 'antd-mobile';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../logo.png';

function Header() {
  let navigate = useNavigate();
  let { pathname } = useLocation();
  let page = pathname.split('/');
  pathname = pathname.replace('/', '');

  const [notification, setNotification] = useState(false);

  useEffect(() => window.scrollTo(0, 0));

  // const handleOpenProfile = () => {
  //   navigate('/perfil');
  // };

  const back = () => navigate(-1);

  return (
    <div className="top-bar top-logo">
      <Card className="" style={{ paddingBottom: "25px", paddingTop: "0px" }}>
        <NavBar
          className=""
          backArrow={pathname !== "" ? true : false}
          onBack={back}
          right={
            <Badge content="">
              <BellOutlined
                fontSize={36}
                onClick={() => {
                  setNotification(true);
                }}
              />
            </Badge>
          }
        >
          <Link to="/">
            <img src={logo} width="auto" height="30px" alt="Logo RTX" />
          </Link>
        </NavBar>
      </Card>

      <Popup
        position="right"
        visible={notification}
        onMaskClick={() => {
          setNotification(false);
        }}
      >
        <div
          style={{
            padding: "24px 0 0 0",
            background: "#090909",
            width: "250px",
            height: "100%",
          }}
        >
          <Space justify="center" block direction="vertical">
            <div
              style={{
                textAlign: "center",
                color: "#fff",
                displey: "flex",
                justifyContent: "center",
              }}
            >
              Notificação
            </div>
          </Space>
        </div>
      </Popup>
    </div>
  );
}

export default Header;
