import { Card } from "antd-mobile";
import React from "react";

const CardDefault = ({ children }) => {
  return (
    <Card
      style={{
        borderRadius: "16px",
        border: "solid 1px #ccc",
        margin: "10px",
        background:
          "radial-gradient(84.28% 84.28% at 13.06% 61.85%, #1D976C 0%, #51CE8F 60.42%, #239E70 89.28%, #50CD8E 100%)",
      }}
    >
      <Card
        style={{
          display: "flex",
          gap: "16px",
        }}
      >
        <Card
          style={{
            flex: 1,
            justifyContent: "start",
            color: "#fff",
          }}
        >
          {children}
        </Card>
      </Card>
    </Card>
  );
};

export default CardDefault;
