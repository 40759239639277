import api from '../../services/api'

// const postWithdrawal = async () => {
//   try {
//     const data = await api.get(`${process.env.REACT_APP_API}/v1/wallet`, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     })
//     return data
//   } catch (error) {
//     console.log(error.message)
//   }
// }

const postWithdrawal = async (dados) => {
  console.log('>> ', dados)
  try {
    const data = await api.put(
      `${process.env.REACT_APP_API}/v1/withdrawal/create`,
      dados,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return data
  } catch (error) {
    console.log(error.message)
  }
}

export function useWithdrawalPostRequest(data) {
  return postWithdrawal(data)
}
