import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
  }
  return config;
}, error => {
  // Tratar erro de autenticação
  if (error.response && error.response.status === 401) {
    // Redirecionar usuário para a página de login
    window.location.href = '/login';
  }
  return Promise.reject(error);
});

export default api;