import { Row } from "antd";
import { Card } from "antd-mobile";
import React from "react";
import { convertTimestampToDate } from "../../Helpers/convertTimestampToDate";
import { formatCurrency } from "../../Helpers/moneyFormat";

function CardAmount({ wallet, user }) {
  return (
    <Card
      style={{
        borderRadius: "16px",
        border: "solid 1px #ccc",
        margin: "10px",
        background:
          "radial-gradient(84.28% 84.28% at 13.06% 61.85%, #1D976C 0%, #51CE8F 60.42%, #239E70 89.28%, #50CD8E 100%)",
      }}
    >
      <Card
        style={{
          display: "flex",
          gap: "16px",
        }}
      >
        <Card
          style={{
            flex: 1,
            justifyContent: "start",
            color: "#fff",
          }}
        >
          <div>
            <h2>Saldo em Operação</h2>
            <h1>{formatCurrency(wallet?.amount)}</h1>
          </div>
        </Card>
      </Card>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          color: "#fff",
          padding: "0 10px",
        }}
      >
        <Row>
          <Card bordered={false}>
            Numero da conta: <br /> {wallet?.account_number}
          </Card>
        </Row>
        <Row>
          <Card bordered={false}>
            since <br />{" "}
            {user?.start_of_contract ??
              convertTimestampToDate(user?.created_at)}
          </Card>
        </Row>
      </Row>
    </Card>
  );
}

export default CardAmount;
