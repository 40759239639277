import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";

const getUserLogged = async () => {
  try {
    const data = await api.get(
      `${process.env.REACT_APP_API}/v1/user/user-online`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    window.location.href = '/login';
    console.log('error: ',error.message);
  }
};

export function useUserLoggedFetchRequest() {
  return useQuery(["user-logged"], () => getUserLogged(), {
    onSuccess: (data) => {
      return data.data.data;
    },
  });
}
