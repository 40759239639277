import { useEffect, useState } from "react";

import { Skeleton, Space } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { lastName } from "../../Helpers/lastName";
import { useUserLoggedFetchRequest } from "../../RQhooks/user/useUser.query";
import Image from "../Image";

function Profile({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  let navigate = useNavigate();

  const { data, isLoading: loadingUser } = useUserLoggedFetchRequest();

  const [notification, setNotification] = useState(false);

  useEffect(() => window.scrollTo(0, 0));

  const handleOpenProfile = () => {
    navigate("/perfil");
  };
  const dataUser = data?.data?.data;
  return (
    <div className="top-bar">
      <Space
        align="center"
        block
        direction="horizontal"
        style={{ padding: "0px 0 20px 0 ", margin: "0px 0 0px 20px" }}
        onClick={handleOpenProfile}
      >
        <Image path={dataUser?.imageFilePath} width={40} height={40} />
        {loadingUser ? (
          <Skeleton.Paragraph lineCount={5} animated />
        ) : (
          <h3 className="investor-name">Olá {lastName(dataUser?.name)}!</h3>
        )}
      </Space>
    </div>
  );
}

export default Profile;
