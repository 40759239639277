export const formatCurrency = (amount = 0, currency = "USD") => {
  const amounts = parseFloat(amount);
  // Use a expressão regular para adicionar pontos de milhar e vírgulas ao número
  const formattedAmount = amounts.toLocaleString("pt-BR", {
    style: "currency",
    currency: "USD",
  });

  return formattedAmount;
};

// export const formatCurrency = (amount, currency = "USD") => {
//   const amounts = parseFloat(amount);
//   // if (isNaN(amounts)) return 0;

//   let locate = "";
//   let currency2 = "";

//   if (currency === "USD") {
//     locate = "en-US";
//     currency2 = "USD";
//   }

//   if (currency === "BRL") {
//     locate = "en-US";
//     currency2 = "USD";
//   }

//   const formattedAmount = amounts.toLocaleString(locate, {
//     style: "currency",
//     currency: currency2
//   });
  
// //   let formattedAmount = amounts.toFixed(2) - (1 / Math.pow(10, 2)) * (2 - 1);
  
// //   const arrayAmount = formattedAmount.toString().split(".");
// //   console.log('1: ',arrayAmount)
// //   if (arrayAmount[1] > 3) {
// //     formattedAmount = `$ ${
// //       arrayAmount[0].toLocaleString(locate, {style: "currency",currency: currency2})
// //     }.${arrayAmount[1].substring(0, 2)}`
// //   }
// //   console.log('2: ', Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(formattedAmount))
  
//   return formattedAmount
// };
