import { Divider, List, Space } from "antd-mobile";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { removeTrailingZeros } from "../../Helpers/formatNumbers";
import { type_format } from "../../Helpers/formatType";
import { formatTypeAmount } from "../../Helpers/formatTypeAmount";
import { formatCurrency } from "../../Helpers/moneyFormat";
import MainButton from "../Button";
import Expense from "../icons/Expense";
import Income from "../icons/Income";

function ListMovement({ moreItems, transactions }) {
  const dataTransactions = transactions?.data;
  let navigate = useNavigate();

  const heddleMoreItems = () => {
    navigate("/movimentacao");
  };

  const invertDate = (originalDate) => {
    let date = originalDate.split("-");
    return `${date[2]}-${date[1]}-${date[0]}`;
  };

  return (
    <>
      <List mode="card" className="card-header" header="Histórico de Transação">
        {transactions?.map((transaction) => (
          <Fragment key={transaction.id}>
            <List.Item
              prefix={
                formatTypeAmount(transaction?.type_transaction) === "credit" ? (
                  <Income classe="income" />
                ) : (
                  <Expense classe="expense" />
                )
              }
              extra={`${formatCurrency(transaction.amount_money)}`}
              description={invertDate(transaction.date)}
              className={`${formatTypeAmount(transaction?.type_transaction)}`}
            >
              <span className="description-movement">
                {type_format(transaction.type_transaction)}
                {removeTrailingZeros(transaction?.percent)}
              </span>
            </List.Item>
            <Divider
              style={{
                color: "#222",
                borderColor: "#222",
                opacity: "0.6",
              }}
            />
          </Fragment>
        ))}
      </List>

      {moreItems && (
        <Space justify="center" style={{ width: "100%" }}>
          <MainButton
            text="ver mais"
            className="button more"
            style={{ width: "300px", height: "50px" }}
            onClick={heddleMoreItems}
          />
        </Space>
      )}
    </>
  );
}

export default ListMovement;
