import { EditFilled, FilePdfOutlined } from "@ant-design/icons";
import { AutoCenter, Card, List, Popup, Skeleton, Space } from "antd-mobile";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { maskDocumentNumber } from "../../Helpers/maskDocumentNumber";
import { useUserLoggedFetchRequest } from "../../RQhooks/user/useUser.query";
import Image from "../../components/Image";
import { logout } from "../../services/auth";
import ProfilePicture from "./../../components/Upload/ProfilePicture";

function Profile() {
  const [profileInformation, setProfileInformation] = useState(false);
  let navigate = useNavigate();
  const { data: user, isLoading } = useUserLoggedFetchRequest();

  const handleOpenPopupProfileInformation = () => {
    setProfileInformation(true);
  };

  if (isLoading) {
    return (
      <>
        <Card>
          <Space
            align="center"
            block
            direction="horizontal"
            style={{
              padding: "30px 0px 70px 30px",
              margin: "0px -15px -60px -10px",
              borderRadius: "50px 50px 0 0",
              background: "#2B2B2B",
            }}
          >
            <Skeleton.Title animated />
            <Skeleton.Paragraph lineCount={5} animated />
          </Space>
        </Card>
      </>
    );
  }
  const dataUser = user?.data?.data;
  return (
    <>
      <Card>
        <Space
          align="center"
          block
          direction="horizontal"
          style={{
            padding: "30px 0px 70px 30px",
            margin: "0px -15px -60px -10px",
            borderRadius: "50px 50px 0 0",
            background: "#2B2B2B",
          }}
        >
          <Image
            path={dataUser?.imageFilePath}
            width={60}
            height={60}
            onClick={handleOpenPopupProfileInformation}
          />
          <Space direction="vertical" style={{ color: "#fff" }}>
            <span>
              <h3 className="">{dataUser?.name}</h3>
              <span>Token - {dataUser?.account_number}</span> <br />
              <span>
                <small>RTX Operações</small>
              </span>
            </span>
          </Space>
        </Space>
      </Card>

      <Card style={{ padding: 0 }}>
        <Space
          align="center"
          block
          direction="vertical"
          className="profile-list-info"
          style={{
            padding: "20px 0 0 0",
            borderRadius: "50px 50px 0 0",
            background: "#050505",
            width: "100%",
            color: "#fff",
          }}
        >
          <h1 style={{ textAlign: "center", color: "#fff" }}>
            Meus dados
            <span
              onClick={() => {
                navigate(`editar/${dataUser?.id}`);
              }}
              style={{ right: "30px", position: "absolute" }}
            >
              <EditFilled />
            </span>{" "}
          </h1>

          <List className="profile-list-info">
            <List.Item>
              Documento:
              {isLoading
                ? "Loading..."
                : maskDocumentNumber(dataUser?.document, 2, 9)}
            </List.Item>
            <List.Item>WhatsApp: {dataUser?.whatsapp}</List.Item>
            <List.Item>Telefone: {dataUser?.phone}</List.Item>
            <List.Item>Email: {dataUser?.email}</List.Item>

            {/* {user?.data.manager && (
              <List.Item
                onClick={() => {
                  navigate(`/gerente/${user?.data.manager}`);
                }}
              >
                Meu gestor
              </List.Item>
            )} */}
            <List.Item>Minha chave Pix: {dataUser?.pix}</List.Item>
            <List.Item
              onClick={() => {
                navigate(`/gerente/${dataUser?.manager}`);
              }}
            >
              Meu gestor: {dataUser?.gerente?.name}
            </List.Item>
            <List.Item>
              {dataUser?.link_document ? (
                <>
                  <a
                    href={`${dataUser?.link_document}?ik-attachment=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfOutlined /> Baixar documento PDF
                  </a>
                </>
              ) : (
                "nao cadastrado"
              )}
            </List.Item>
            <List.Item
              onClick={() => {
                logout();
              }}
            >
              Sair
            </List.Item>
          </List>
        </Space>
      </Card>

      <Popup
        visible={profileInformation}
        onMaskClick={() => {
          setProfileInformation(false);
        }}
        onClose={() => {
          setProfileInformation(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          minHeight: "25vh",
          background: "#222",
          paddingTop: "20px",
        }}
      >
        <AutoCenter>
          <Image path={dataUser?.imageFilePath} width={100} height={100} />
        </AutoCenter>
        <AutoCenter>
          <ProfilePicture id={dataUser?.id} title={dataUser?.name} />
        </AutoCenter>
      </Popup>
    </>
  );
}

export default Profile;
