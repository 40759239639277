import { Layout } from "antd";
import { Result } from "antd-mobile";
import React from "react";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/Button";
import logo from "../../logo.png";

function Success() {
  let navigate = useNavigate();
  return (
    <Layout className={`layout-login`}>
      <div className="">
        <img src={logo} alt="" style={{ height: "80px" }} />
      </div>

      <Result
        status="success"
        title="Sucesso!"
        description="Instruções para redefinir sua senha foram enviadas para o endereço de
          e-mail fornecido. Por favor, verifique sua caixa de entrada e siga as
          etapas descritas para concluir a redefinição de sua senha."
        style={{ color: "#fefefe" }}
      />

      <MainButton
        text="Retornar a pagina inicial"
        className="button more"
        onClick={() => {
          navigate("/login");
        }}
        style={{ width: "85%" }}
      />
    </Layout>
  );
}

export default Success;
