/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useQuery } from "@tanstack/react-query";
import { Affix, Drawer, Layout } from "antd";
import { PullToRefresh, Space } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CardAmount from "../CardAmount";
import CardTotalWithdrawal from "../CardTotalWithdrawal";
import ListMovement from "../ListMovement";
import BottomBar from "./BotomBar";
import FooterPage from "./FooterPage";
import Header from "./Header";
import Profile from "./Profile";
import Sidenav from "./Sidenav";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ user }) {
  async function doRefresh() {
    await sleep(1000);
    window.location.reload();
  }

  const statusRecord = {
    pulling: "Puxar",
    canRelease: "Atualizar",
    refreshing: "Atualizando...",
    complete: "Completo",
  };

  return (
    <>
      <PullToRefresh
        onRefresh={doRefresh}
        renderText={(status) => {
          return <div>{statusRecord[status]}</div>;
        }}
      >
        <div>
          <Layout className={`layout-app`}>
            <Header />
            <Profile />

            <Content className="container">
              <Outlet />
            </Content>
          </Layout>
        </div>
      </PullToRefresh>
      <BottomBar className="backdrop" />
    </>
  );
}

export default Main;
