import React from "react";

// import { Container } from './styles';

function Expense({ classe }) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`lucide lucide-arrow-down-left ${classe}`}><line x1="17" x2="7" y1="7" y2="17"></line><polyline points="17 17 7 17 7 7"></polyline></svg>
    </>
  );
}

export default Expense;
