const types = {
  1: 'credit',
  2: 'credit',
  3: 'credit',
  4: 'credit',
  5: 'debit',
  6: 'credit',
  7: 'credit',
  8: 'credit',
}
export const formatTypeAmount = (type) => {
  if (!types.hasOwnProperty(type)) {
    return 'unknown'
  }
  return types[type]
}
