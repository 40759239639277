import { IKImage } from "imagekitio-react";
import React from "react";

const Image = ({ path, ...props }) => {
  return (
    // <img src={`https://ik.imagekit.io/qqrtx9mgqo/${path}`} {...props} />
    <IKImage
      urlEndpoint="https://ik.imagekit.io/qqrtx9mgqo/"
      path={path || "./assets/user.png"}
      loading="lazy"
      transformation={[{ quality: 80 }]}
      lqip={{ active: true }}
      style={{
        borderRadius: "50%",
        aspectRatio: 1 / 1,
        objectFit: "cover",
      }}
      {...props}
    />
  );
};

export default Image;
