export const showButtonBetweenDates = () => {
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    31
  );

  if (currentDate >= startDate && currentDate <= endDate) {
    return true;
  } else {
    return false;
  }
};
